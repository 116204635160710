@import "./reset";
@import "./common";
@import "./thankyou";
@import "./check";
@import "./result";

/* main */
#main{
    >.inner {
        width: 100%;
        @include tb {
            max-width: 1000px;
            width: 100%;
            margin: auto;
            padding-left: 0;
            padding-right: 0;
            box-sizing: border-box;   
        }
        @include sp {
            width: 100%;
            padding: 0;
        }
    }
    #slider {
        margin-bottom: 60px;
    }
    .slick-list {
        background: #f0f0f0;
    }
    .slider-item {
        &.--uvserum {
            position: relative;
            .slide-item-title {
                position: absolute;
                left: 50%;
                top: 144px;
                margin-left: -503px;
                @include tb {
                    top: 98px;
                    width: 221px;
                    margin-left: -343px;
                }
                @include sp {
                    top: calcSp(70);
                    left: 50%;
                    width: calcSp(647);
                    margin-left: 0;
                    transform: translate(-50%,0);
                }
            }
        }
        &.--bodyserve {
            position: relative;
            .slide-item-title {
                position: absolute;
                left: 50%;
                top: 109px;
                margin-left: -486px;
                @include tb {
                    top: 73px;
                    width: 226px;
                    margin-left: -328px;
                }
                @include sp {
                    top: calcSp(70);
                    left: 50%;
                    width: calcSp(556);
                    margin-left: 0;
                    transform: translate(-50%,0);
                }
            }
        }
        &.--defential {
            position: relative;
            .slide-item-title {
                position: absolute;
                left: 50%;
                top: 109px;
                margin-left: -479px;
                @include tb {
                    top: 73px;
                    width: 226px;
                    margin-left: -330px;
                }
                @include sp {
                    top: calcSp(70);
                    left: 50%;
                    width: calcSp(531);
                    margin-left: 0;
                    transform: translate(-50%,0);
                }
            }
            .slide-item-caution {
                position: absolute;
                left: 50%;
                bottom: 35px;
                margin-left: -503px;
                @include tb {
                    bottom: 25px;
                    width: 107px;
                    margin-left: -347px;
                }
                @include sp {
                    bottom: calcSp(79);
                    left: calcSp(50);
                    width: calcSp(224);
                    margin-left: 0;
                }

            }
        }
    }
    .owl-carousel {
        .owl-item {
            width: 100%;
            height: 0;
            padding-bottom: 583px; 
            outline: none;
            @include tb {
                height: 0;
                padding-bottom: 400px;   
            }
            @include sp {
                padding-bottom: 0;
                height: auto;   
            }
            >div {
                position: relative;
                width: 100%;
                height: 583px;
                overflow: hidden;
                @include tb {
                    height: 400px;   
                }
                @include sp {
                    height: auto;
                }
                .img {
                    position: absolute;
                    left: 50%;
                    display: block;
                    width: auto;
                    margin-left: -1500px;
                    @include tb {
                        width: 2058px;
                        margin-left: -1029px;   
                    }
                    @include sp {
                        position: relative;
                        display: block;
                        width: 100%;
                        margin-left: 0;
                        left: 0;   
                    }
                    &.sp {
                        display: none;
                        @include sp {
                            display: block;
                            width: 100%;   
                        }
                    }
                    
                    &.pc_tablet {
                        display: block;
                    }
                    .pc {
                        @include sp {
                            display: none;   
                        }
                    }
                    
                }
            }
            .bnr_diagnosis {
                position: absolute;
                left: 50%;
                bottom: 50px;
                margin-left: 295px;
                @include tb {
                    left: auto;
                    right: 20px;
                    bottom: 50px;
                    margin-left: 295px;   
                }
                @include sp {
                    right: 0;
                    bottom: 0;
                    margin: 0 10.52% 13.94%;
                    width: 21.71%;
                }
                img {
                    &.pc {
                        @include sp {
                            display: none; 
                        }
                    }
                    &.pc_tablet {
                        @include sp {
                            display: none;   
                        }
                    }
                    &.sp {
                        display: none;
                        @include sp {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    .owl-dots {
        text-align: center;
        button {
            width: 7px;
            height: 7px;
            margin-right: 20px;
            background: #B2B2B2;
            border-radius: 5px;

            &.active {
                background: #000;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

}


/* lp */
#lp {
    > .inner {
        padding-bottom: 150px;
        @include sp {
        	width: 78.94%;
            padding: 0 0 24.6%; 
        }
    }
    .p-lp {
        &List {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
            justify-content: center;
            @include tb {
                justify-content: space-between;   
            }
            @include sp {
                display: block;
            }
            &Item {
                position: relative;
                width: 317px;
                @include tb {
                    width: 30%;
                    margin-right: 0;   
                }
                @include sp {
                    width: 100%;
                    margin-bottom: 9.16%;
                    margin-right: 0;   
                }
                img {
                    max-width: 100%;
                }
                a {
                    
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        display: block;
                        opacity: 0;
                        z-index: 2;
                        transition: .5s;
                    }
                    &:hover {
                        opacity: 1;
                        &:before {
                            opacity: 1;
                        }
                    }
                }
                &.--skincare {
                    a {
                        &:hover {
                            &:before {
                                background: url(../img/index/cover_uv_serum.svg) top center / cover no-repeat;
                                @include sp {
                                    background: url(../img/index/cover_uv_serum_sp.svg) top center / cover no-repeat;
                                }
                            }
                        }
                    }
                }
                &.--bodyserve {
                    a {
                        &:hover {
                            &:before {
                                background: url(../img/index/cover_bodyserve.svg) top center / cover no-repeat;
                                @include sp {
                                    background: url(../img/index/cover_bodyserve_sp.svg) top center / cover no-repeat;
                                }
                            }
                        }
                    }
                }
                &.--defential {
                    a {
                        &:hover {
                            &:before {
                                background: url(../img/index/cover_defential.svg) top center / cover no-repeat;
                                @include sp {
                                    background: url(../img/index/cover_defential_sp.svg) top center / cover no-repeat;
                                }
                            }
                        }
                    }
                }
                &:last-child {
                    @include sp {
                        margin-bottom: 0;
                    }
                }
                &.new:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 59px;
                    height: 59px;
                    margin: -15px -15px 0 0;
                    background: url(../img/index/icon_new.svg) top center / contain no-repeat;
                    z-index: 1;
                    @include sp {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 16.66%;
                        height: 0;
                        padding-bottom: 16.66%;
                        margin: -4% 0 0 -8.33%;
                        background: url(../img/index/icon_new.svg) top center / contain no-repeat;
                        z-index: 1;
                    }
                }
        
                img {
                    position: relative;
                    z-index: 1;
                    @include tb {
                        width: 100%;   
                    }
                }
            }
        }
    }
    
    

}


/* information */
#information {
	padding: 100px 0 140px;
	background: #F0F0F0;
    @include sp {
        padding: 17.23% 0 18.42%;
        background: #F0F0F0;
    }
    >.inner {
        @include sp {
            width: 85.52%;
            padding: 0;       
        }
    }
    h3 {
        margin-bottom: 40px;
        text-align: center;
        @include sp {
            width: 42.46%;
            margin: 0 auto 6.92%;
            text-align: center;
        }
    }
    ul {
        width: 1000px;
        margin: auto;
        border-top: solid 1px #000;
        @include tb {
            width: 100%;   
        }
        @include sp {
            width: 100%;
        }
    }
    li {
        position: relative;
        padding: 10px 0 35px;
        border-bottom: solid 1px #000;
        @include sp {
            padding: 3% 0 7.6%;
        }
        >p {
            margin-left: 155px;
            text-align: left;
            font-size: 18px;
            font-feature-settings: "halt" 1;
            letter-spacing: 0.1em;
            line-height: 2.1;
            @include sp {
                margin-left: 0;
                padding: 0 8.66% 0 11.53%;
                font-size: 2.9vw;
                text-align: left;   
            }
            a {
                text-decoration: underline;
            }
        }
        >.date {
            display: block;
            font-weight: bold;
            font-size: 16px;
            margin-bottom: 10px;
            line-height: 1;
            @include sp {
                margin-bottom: 3%;
                font-size: 2.6vw;   
            }
        }
    }
}

/* about */
/*#about {
	padding: 240px 0 130px;
	text-align: center;
    @include sp {
        padding: 30.26% 0 26.31%;   
    }
    & h3 {
        margin-bottom: 65px;
        @include sp {
            margin: 0 auto 7.8%;
            width: 63.15%;
        }
    }
    .logo {
        margin-bottom: 60px;
        @include sp {
            width: 11.97%;
            margin-bottom: 7.89%;   
        }
        &+ p {
            font-size: 16px;
            line-height: 2.2;
            @include sp {
                line-height: 2.2;
                font-size: 2.9vw;   
            }
        }
    }
    
}*/


/*philosophy*/
.p-philosophy {
    min-height: 797px;
    padding: 150px 0 0;
    background: url(../img/index/bg_philosophy.jpg) top center / auto no-repeat;
    text-align: center;
    @include sp {
        min-height: calcSp(988);
        padding: calcSp(175) 0 0;
        background: url(../img/index/bg_philosophy_sp.jpg) bottom center / cover no-repeat;
    }
    &Title{
        display: block;
        
        margin-bottom: 65px;
        @include sp {
            width: calcSp(238);
            margin: 0 auto calcSp(75);
        }
    }
    &Text{
        font-size: 16px;
        line-height: 2.37;
        text-align: center;
        font-weight: 500;
        font-feature-settings: 'palt' 1;
        letter-spacing: 0.1em;
        @include sp {
            font-size: calcSp(25);
        }
        &__sec {
            display: block;
            margin-bottom: 15px;
            
        }
    }
}

/* media */
#media {
	position: relative;
	text-align: center;
    @include sp {
        background: #fff;
    }
    &>.inner {
        position: relative;
        padding: 130px 0 0;
        margin-bottom: 150px;
        z-index: 2;
        @include tb {
            padding: 130px 5% 75px;   
        }
        @include sp {
            width: calcSp(515);
            padding: calcSp(180) 0 calcSp(175);
            margin-bottom: 0;   
        }
    }
    h3 {
        margin-bottom: 90px;
        @include sp {
            width: 24%;
            margin: 0 auto 11.8%;   
        }
    }
    ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 95px;
        gap: 35px;
        @include tb {
            justify-content: center;
        }
        @include sp {
            margin: 0 auto calcSp(70);
            gap: calcSp(60) calcSp(45);  
        }
        li {
            position: relative;
            
            @include tb {
                margin-right: 0;
                width: 20%;   
            }
            @include sp {
                width: calcSp(220);
                margin-right: 0;
                margin-bottom: 0;   
            }
            &:last-child {
                margin-right: 0;
            }
            &:nth-child(2n) {
                @include sp {
                    margin-right: 0;
                }
            }
            &> img {
                margin-bottom: 20px;
                @include tb {
                    width: 100%;   
                }
                @include sp {
                    margin-bottom: 7%;   
                }
            }
            p {
                line-height: 1;
                @include sp {
                    font-size: calcSp(16);
                }
            }
            &:before {
                @include sp {
                    content: none;   
                }
            }
            &.new:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 59px;
                height: 59px;
                margin: -20px 0 0 -20px;
                background: url(../img/index/icon_new.svg) top center / contain no-repeat;
                z-index: 1;
                @include sp {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 26.8%;
                    height: 0;
                    padding-bottom: 26.8%;
                    margin: -10% 0 0 -10%;
                    background: url(../img/index/icon_new.svg) top center / contain no-repeat;
                    z-index: 1;   
                }
            }
        }
    }
    a {
        @include sp {
            display: block;
            width: 36.29%;
            margin: auto;   
        }
    }
}


#modal_movie {
	visibility: hidden;
	position: fixed;
	top: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0,0,0,.5);
	z-index: 300;
	opacity: 0;
	transition: .5s;
}


/* voice */
.p-voice {
	background: url("../img/index/bg_voice.png") top center / cover #fff;
    @include sp {
        margin-bottom: 32.89%;   
    }
    > .inner {
        padding: 145px 0 260px;
        @include sp {
            padding: 17.33% 0 20.52%;   
        }
        >div {
            text-align: center;
            a {
                @include sp {
                    display: block;
                    width: 26.31%;
                    margin: auto;   
                }
            }
        }
    }
    &Title {
        margin-bottom: 55px;
        text-align: center;
        @include sp {
            width: 15.92%;
            margin: 0 auto 7.23%;
            text-align: center;   
        }
        & + p {
            margin: 0 auto 90px;
            font-size: 16px;
            line-height: 2;
            width: 590px;
            text-align: left;
            font-feature-settings: 'palt' 1;
            letter-spacing: 0.01em;
            font-weight: 500;

            @include sp {
                width: 100%;
                margin-bottom: 19.73%;
                font-size: calcSp(25);
                line-height: calcSp(62);
                text-align: center;
            }
        }
    }
    &List {
        width: 680px;
        margin: auto;
        @include sp {
            width: calcSp(580);
        }
        &Item {
            position: relative;
            background: #fff;
            margin-bottom: 90px;
            padding: 10px;
            @include sp {
                padding: calcSp(30) calcSp(40);
                margin-bottom: calcSp(105);
            }
            &:before ,&:after {
                position: absolute;
                content: '';
                display: block;
                width: 25px;
                height: 25px;
                pointer-events: none;
            }
            &:before {
                top: 0;
                left: 0;
                border-top: solid 1px #000;
                border-left: solid 1px #000;
            }
            &:after {
                bottom: 0;
                right: 0;
                border-bottom: solid 1px #000;
                border-right: solid 1px #000;
            }
            &__inner {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 37px;
                @include sp {
                    display: block;
                    gap: 0;
                }
            }
            &Text {
                width: 400px;
                height: 110px;
                text-align: left;
                font-weight: 600;
                line-height: 1.8;
                font-feature-settings: "palt" 1;
                letter-spacing: 0.1em;
                word-break: inherit;
                text-align: justify;
                @include sp {
                    width: 100%;
                    height: auto;
                    font-size: calcSp(20);
                }
            }
            &Img {
                position: absolute;
                top: -30px;
                right: 0;
                transform: translate(50%, 0);
                z-index: 3;
                @include sp {
                    transform: translate(50%,0);
                }
                &.--defential {
                    width: 55px;
                    @include sp {
                        top: calcSp(-43);
                        width: calcSp(73);
                    }
                }
                &.--bodyserve {
                    width: 55px;
                    @include sp {
                        top: calcSp(-43);
                        width: calcSp(73);
                    }
                }
                &.--uv_serum {
                    width: 90px;
                    top: -45px;
                    @include sp {
                        top: calcSp(-70);
                        width: calcSp(119);
                    }
                }
            }
        }
    }
    &Profile {
        width: 150px;
        text-align: center;
        @include sp {
            display: flex;
            margin-bottom: calcSp(40);
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: calcSp(20);
            width: 100%;
            align-items: center;
        }
        img {
            width: 95px;
            margin: 0 auto 10px;
            @include sp {
                width: calcSp(127);
                margin: 0;
            }

        }
        &Text {
            font-size: 13px;
            text-align: center;
            font-weight: 600;
            font-feature-settings: 'palt' 1;
            @include sp {
                text-align: left;
                font-size: calcSp(17);
            }
        }
    }

}