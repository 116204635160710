@keyframes toBlack {
  0% {
    left: -200%;
  }
  100% {
    left: -60%;
  }
}
@keyframes toWhite {
  0% {
    left: -60%;
  }
  100% {
    left: 100%;
  }
}

#t-wrap {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
  z-index: 300;

}

#thankyou {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 618px;
  height: 150px;
  transform: translate(-50%, -60%);
  overflow: hidden;
  @include sp {
    width: 101%;
    height: 0;
    padding-bottom: 63.4%;
  }
  &.show {
    .gradation {
      animation: 5s toBlack forwards;
    }
    &.leave {
      .gradation {
        animation: 5.5s toWhite forwards;
      }
    }
  }
}

.message {
  position: relative;
  z-index: 1;
  img {
    margin: 0;
    @include sp {
      width: 100%;
    }
  }
}

.gradation {
  position: absolute;
  top: 0;
  left: -400%;
  width: 200%;
  height: 100%;
  background: url(../img/index/m-bg.jpg) center no-repeat;
  background-size: 100% 100%;
  @include sp {
    top: 0.5%;
    height: 99%;
  }
}