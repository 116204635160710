#result {
    min-width: auto;
    font-size: 16px;
    line-height: 2;
    text-align: center;
    section {
        min-width: auto;
    }
    div.inner {
        width: 650px;
        padding-top: 15px;
        padding-bottom: 40px;
        margin: auto;
    }
    h3 {
        position: relative;
        padding-bottom: 30px;
        font-size: 30px;
        font-weight: bold;
        line-height: 1.6;
        text-align: center;
        letter-spacing: 0.1em;
        &>div {
            display: inline-block;
        }
        &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 60px;
            height: 2px;
            background: #000;
            -moz-transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translate(-50%);
        }
    }
    h4 {
        >div {
            display: inline-block;
            font-size: 18px;
            line-height: 1.8;
            font-weight: bold;
            text-align: center;
            border-bottom: 1px solid #000;
            letter-spacing: 0.2em;
        }
    }
    [id*="-text"] {
        text-align: left;
    }
    #data-constitution-text,
    #data-condition-text,
    #data-problem-text {
        width: 540px;
        margin: auto;
    }
    #constitution {
        #check_text {
            width: 267px;
        }
        h3 {
            margin-top: 100px;
        }
        #data-percentage {
            margin: 75px auto 60px;
            text-align: center;
        }
    }
    #condition {
        h3 {
            margin: 200px auto 0;
        }
        h4 {
            margin: 95px auto 0;
        }
        #data-condition {
            margin: 15px auto 30px;
        }
        #data-condition-text {
            margin-bottom: 190px;
        }
        #data-problem {
            font-size: 45px;
            line-height: 1.5;
            margin: 70px auto 55px;
        }
        #keycomponent {
            margin: 180px auto 250px;
            h3 {
                margin: 0 auto 80px;
            }
            li {
                margin-bottom: 80px;
                &:last-child {
                    margin-bottom: 0;
                }
                &>div {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            .img_area {
                width: 200px;
                >img {
                    width: 100%;
                }
            }
            .text_area {
                width: 315px;
                margin-left: 20px;
                font-size: 14px;
                line-height: 1.5;
                text-align: left;
            }
            .component_nm {
                margin-bottom: 10px;
                font-size: 15px;
                font-weight: bold;
                >span {
                    font-size: 20px;
                }
            }
        }
    }
    #howto {
        background: #f5f5f5;
        padding: 60px 0 120px;
        h3 {
            margin: 0 auto 85px;
        }
        #data-howto {
            margin: 60px auto 50px;
            font-size: 30px;
            line-height: 1.6;
        }
        span {
            font-size: 50px;
            line-height: 1.4;
            small {
                font-size: 27px;
            }
        }
    }
    #recommend {
        padding: 240px 0 40px;
        img:hover,
        a:hover {
            opacity: 1;
            text-decoration: none;
        }
        #data-recommend {
            margin: 110px auto 0;
        }
        #data-recommend-text {
            margin: 45px auto 0;
            font-size: 70px;
            text-align: center;
            line-height: 1;
            div {
                display: inline-block;
            }
        }
        #data-recommend-goods {
            margin: 45px auto 0;
            a img{
                width: 70%;
                height: 80%;
                padding: 3px;
                border: thin solid;
            }
        }
    }
    #products,
    #products2 {
        text-align: left;
        h3 {
            padding-bottom: 0;
            &:after {
                display: none;
            }
            .cont {
                margin: 0 auto 240px;
            }
        }
    }
    #course,
    #course2 {
        background: #f5f5f5;
        margin-bottom: 0;
        padding: 60px 0 80px;
        text-align: left;
        h3 {
            display: none;
            &:after {
                display: none;
            }
        }
        div.inner {
            width: 700px;
        }
        .list {
            dl {
                width: 265px;
                margin-bottom: 35px;
                padding-left: 65px;
                background-size: 43px;
                box-sizing: content-box;
                &:nth-child(odd) {
                    margin-right: 40px;
                }
            }
            dt {
                font-size: 20px;
                img {
                    width: 100%;
                }
            }
            dd {
                font-size: 14px;
                line-height: 1.8;
            }
        }
    }

    #banner {
        .inner {
            padding: 60px 0;
        }
        .size {
            margin-top: 0;
            &1 {
                margin-bottom: 30px;
            }
            &3 {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                margin: 45% 0 0;
                width: 65%;
            }
            &2 {
                display: none;
            }
            &4 {
                display: none;
            }
            &6 {
                display: none;
            }
        }
        .firsttime {
            position: relative;
            width: 650px;
            &>img {
                max-width: 100%;
            }
        }
        .teiki {
            position: relative;
            width: 650px;
            .size {
                &4 {
                    display: none;
                }
                &6 {
                    display: none;
                }
            }
            >img {
                max-width: 100%;
            }
        }
    }
}


@media (max-width: 767px) {

    #result {
        font-size: 3.582vw;
        line-height: 1.8;
    }
    #result div.inner {
        width: 88.05%;
    }
    #result h3 {
        padding-bottom: 6.26%;
        font-size: 5.075vw;
        line-height: 1.6;
    }
    #result h3:after {
        width: 10.33%;
        height: 1px;
    }
    #result h4>div {
        font-size: 4.179vw;
    }

    #result #data-constitution-text,
    #result #data-condition-text,
    #result #data-problem-text {
        width: 100%;
    }
    #result #constitution #check_text {
        width: 62.71%;
    }
    #result #constitution h3 {
        margin-top: 17.91%;
    }
    #result #constitution #data-percentage {
        margin: 11.94% auto 8.95%;
    }
    #result #constitution #data-percentage img {
        width: auto;
        height: 19.112vw;
    }

    #result #condition h3 {
        margin: 28.35% auto 0;
    }
    #result #condition h4 {
        margin: 11.94% auto 0;
    }
    #result #condition #data-condition {
        margin: 2.98% -5.9%;
    }
    #result #condition #data-condition-text {
        margin-bottom: 24.92%;
    }
    #result #condition #data-problem {
        font-size: 6.716vw;
        margin: 10.44% auto 9.7%;
    }

    #result #keycomponent {
        margin: 28.35% auto 29.4%;
    }
    #result #keycomponent h3 {
        margin: 0 auto 13.43%;
    }
    #result #keycomponent li {
        margin-bottom: 13.43%;
    }
    #result #keycomponent .img_area {
        display: block;
        width: 42.37%;
        margin: auto; 
    }
    #result #keycomponent .img_area>img {
        width: 100%;
    }
    #result #keycomponent .text_area {
        width: 100%;
        margin-left: 0;
        font-size: 3.582vw;
        line-height: 1.8;
    }
    #result #keycomponent .component_nm {
        margin: 2.98% auto;
        font-size: 3.582vw;
        text-align: center;
        line-height: 1.3;
        font-weight: bold;
    }
    #result #keycomponent .component_nm>span {
        font-size: 4.478vw;
    }

    #result #howto {
        padding: 6.26% 0 14.17%;
    }
    #result #howto h3 {
        margin: 0 auto 13.13%;
    }
    #result #howto #data-howto {
        margin: 10.14% auto 5.22%;
        font-size: 4.478vw;
    }
    #result #howto #data-howto span {
        font-size: 7.463vw;
    }
    #result #howto #data-howto span small {
        font-size: 3.906vw;
    }

    #result #recommend {
        padding: 28.35% 0 26.04%;
    }
    #result #recommend #data-recommend {
        margin: 14.17% auto 0;
    }
    #result #recommend #data-recommend img {
        width: 15.25%
    }
    #result #recommend #data-recommend-text {
        margin: 6.71% auto 0;
        font-size: 10.448vw;
    }

    #result #products>.inner,
    #result #products2>.inner {
        width: 100%;
    }
    #result #products .cont,
    #result #products2 .cont {
        margin: 5.07% auto 34.32%;
    }

    #result #course,
    #result #course2 {
        margin-bottom: 0;
        padding: 0;
        text-align: left;
    }
    #result #course h3,
    #result #course2 h3 {
        display: block;
        background: #fff;
        padding-bottom: 8.35%;
    }
    #result #course div.inner,
    #result #course2 div.inner {
        width: 82.08%;
        padding: 11.19% 0 9.4%;
    }
    #result #course .list dl,
    #result #course2 .list dl {
        width: 100%;
        margin-bottom: 10.44%;
        padding-left: 19.09%;
        background-size: 12.9%;
    }
    #result #course .list dl:nth-child(odd),
    #result #course2 .list dl:nth-child(odd) {
        margin-right: 0;
    }
    #result #course .list dt,
    #result #course2 .list dt {
        font-size: 4.34vw;
        margin-bottom: 2.98%;
    }
    #result #course .list dd,
    #result #course2 .list dd {
        font-size: 3.433vw;
        line-height: 1.7;
    }
	
	/*20210616記述*/


	#result #banner .size {
		margin-top: 0;
	}

	#result #banner .size1 {
		margin-bottom: 30px;
	}

	#result #banner .inner .size4 {
		position: absolute;
		top: 0;
		left: 0;
		margin: 115% 0 0px;
		width: 100%;
	}

	#result #banner .firsttime {
		position: relative;
		width: 100%;
	}
	#result #banner .inner .size1 {
		display: none;
	}
	#result #banner .inner .size2 {
		display: block;
		width: 100%;
	}

	#result #banner .firsttime .size5 {
		display: none;
	}
	#result #banner .firsttime .size6 {
		display: block;
		width: 100%;
		margin-top: 10%;
	}
	#result #banner .firsttime .size3 {
		display: none;
	}
	#result #banner .firsttime .size4 {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		margin: 117% 0 0px;
		width: 95%;
	}

    #result #banner .teiki {
		position: relative;
		width: 100%;
	}
    
	#result #banner .teiki .size5 {
		display: none;
	}
	#result #banner .teiki .size6 {
		display: block;
		width: 100%;
		margin-top: 10%;
	}
	#result #banner .teiki .size3 {
		display: none;
	}
	#result #banner .teiki .size4 {
		display: block;
		position: absolute;
        top: 0;
        left: 0;
        margin: 94% 0 0px;
        width: 100%;
	}


	/*20210616記述　ここまで*/

}
