/* @include _breakpoints.scss */

/* break point media query */
@mixin tb {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin sp {
  @media (max-width: 767.9px) {
    @content;
  }
}


@function calcSp($pixel) {
  @return calc($pixel / 760) * 100vw;
}
@function calcTb($pixel) {
  @return calc($pixel / 1024) * 100vw;
}


.pc {
  @include sp {
    display: none!important;
  }
  &_i {
    display: inline;
    @include sp {
      display: none;
    }
  }
  &_ib {
    display: inline-block;
    @include sp {
      display: none;
    }
  }
}
.sp {
  display: none;
  @include sp {
    display: block;
  }
  &_i {
    display: none;
    @include sp {
      display: inline;
    }
  }
  &_ib {
    display: none;
    @include sp {
      display: inline-block;
    }
  }
}
.tb {
  display: none;
  @include sp {
    display: none;
  }

  &_i {
    display: none;
    @include sp {
      display: none;
    }
  }
  &_ib {
    display: none;
    @include sp {
      display: none;
    }

  }
}

