button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
}

body.no_scroll {
    overflow: hidden;
}

#modal {
    visibility: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    z-index: 200;
    opacity: 0;
    transition: .5s;

    &.open {
        visibility: visible;
        opacity: 1;

        #check {
            .start {
                .btn_next {
                    visibility: visible;
                }
            }
        }
    }

    .btn_next {
        position: relative;
        left: auto;
        bottom: auto;
        display: block;
        visibility: visible;
        margin: auto;
        margin-left: 15px;
        opacity: 1;
        transition: .5s;

        @include sp {
            position: relative;
            left: 0;
            bottom: 0;
            display: block;
            width: 38.59%;
            margin-top: 0;
            margin-bottom: 15%;
            margin-left: 5%;
        }

        &:disabled {
            opacity: .5;
            visibility: visible;
            cursor: auto;
            transition: .5s;
        }
    }

    &.num_check10 {
        .modal_inner {
            >.scroll {
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;
            }
        }
    }

    
    .bar {
        &:after {
            position: absolute;
            display: block;
            content: '';
            height: 0;
            padding-bottom: 10px;
            width: 100%;
            background: #231815;
            z-index: 202;
            display: none;
        }

        &:before {
            position: absolute;
            display: block;
            content: '';
            height: 0;
            padding-bottom: 10px;
            width: 10%;
            background: #231815;
            z-index: 202;
        }
    }

    .bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 10px;
        width: 100%;
        background: #EFEEEE;
        z-index: 1;

        @include sp {
            height: 0;
            padding-bottom: 2.13%;
        }

        &:before {
            position: absolute;
            display: block;
            content: '';
            height: 0;
            padding-bottom: 10px;
            width: 10%;
            background: #231815;
            z-index: 202;

            @include sp {
                height: 0;
                padding-bottom: 2.13%;
                width: 100%;
            }
        }

        &:after {
            @include sp {
                height: 0;
                padding-bottom: 2.13%;
                width: 100%;
            }
        }
    }

    &.num_check {
        &0 {
            .scroll {
                @include sp {
                    background: url(../img/index/bg_check.jpg) center /cover no-repeat;
                }
            }
        }

        &1 {
            .bar {
                &:before {
                    width: 10%;
                    transition: .5s;
                }
            }
        }

        &2 {
            .bar {
                &:before {
                    width: 20%;
                    transition: .5s;
                }
            }
        }

        &3 {
            .bar {
                &:before {
                    width: 30%;
                    transition: .5s;
                }
            }
        }

        &4 {
            .bar {
                &:before {
                    width: 40%;
                    transition: .5s;
                }
            }
        }

        &5 {
            .bar {
                &:before {
                    width: 50%;
                    transition: .5s;
                }
            }
        }

        &6 {
            .bar {
                &:before {
                    width: 60%;
                    transition: .5s;
                }
            }
        }

        &7 {
            .bar {
                &:before {
                    width: 70%;
                    transition: .5s;
                }
            }
        }

        &8 {
            .bar {
                &:before {
                    width: 80%;
                    transition: .5s;
                }
            }
        }

        &9 {
            .bar {
                &:before {
                    width: 90%;
                    transition: .5s;
                }
            }
        }

        &10 {
            .bar {
                &:before {
                    width: 100%;
                    transition: .5s;
                }
            }
        }
    }

    .modal_inner {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 800px;
        min-height: 610px;
        max-height: 610px;
        margin: auto;
        background: #fff;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        @include sp {
            position: absolute;
            top: 0;
            left: 0;
            width: 88.15%;
            height: 80vh;
            min-height: inherit;
            margin: 10% 5.925% 0;
            max-height: inherit;
            transform: inherit;
        }

        &>.scroll {
            overflow-y: hidden;
            width: 800px;
            min-height: 610px;
            max-height: 610px;

            @include sp {
                overflow-y: scroll;
                width: 100%;
                height: 83vh;
                min-height: inherit;
                max-height: inherit;
                background: #fff;
            }
        }

        .start {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            min-height: 610px;
            width: 100%;
            background: url(../img/index/bg_check.jpg) center /cover no-repeat;
            text-align: center;

            @include sp {
                display: none;
                position: relative;
                top: 0;
                left: 0;
                min-height: inherit;
                width: 100%;
                height: 83vh;
                background: none;
                text-align: center;
            }

            p {
                @include sp {
                    width: 61.79%;
                    margin: auto;
                    padding: 34.32% 0 9.86%;
                }
                >img {
                    @include sp {
                        width: 100%;
                    }
                }
            }

            .btn_next {
                display: block;
                margin: auto;
                bottom: auto;
                visibility: hidden;

                >img {
                    width: 155px;

                    @include sp {
                        width: 100%;
                    }
                }
            }

            &.active {
                display: block;

                &:before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '';
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-bottom: 2.13%;
                    background: #231815;
                    z-index: 1;
                }

                p {
                    padding: 110px 0 50px;

                    >img {
                        width: 493px;
                        @include sp {
                            width: 100%;
                            height: auto;   
                        }
                    }
                }
            }

            h2 {
                padding-bottom: 60px;
                @include sp {
                    display: block;
                    width: 76.71%;
                    margin: auto;
                    padding-bottom: 11.18%;
                }
                &>img {
                    width: 390px;
                    height: auto;
                    @include sp {
                        width: 100%;
                        height: auto;   
                    }
                }
            }
            
        }







        .question {
            dd {
                height: 190px;
                @include sp {
                    font-size: 3.582vw;
                    min-height: 150px;
                    padding-bottom: 15%;
                    height: auto;
                }
            }
        }

        input[type="text"] {
            width: 200px;
            padding: 10px 5px;
            font-size: 18px;
            text-align: center;
            border: solid 1px #5C5B5C;
            @include sp {
                width: 47.36%;
                padding: 3% 2%;
                font-size: 3.947vw;
                text-align: center;
            }
        }
    }

}



    #check {
        .num_q {
            padding: 90px 0 35px;
            @include sp {
                width: 16.71%;
                margin: auto;
                padding: 24.6% 0 9.47%;
            }
            >img {
                height: 18px;
                @include sp {
                    height: auto;
                }
            }
        }

        .check_btn_area {
            display: block;
            text-align: center;
            min-height: 1px;
            height: 1px;

            &:after {
                content: ".";
                display: block;
                visibility: hidden;
                clear: both;
                height: 0;
                font-size: 0;
                line-height: 0;
            }

            button {
                display: inline-block;
                @include sp {
                    width: auto;
                    height: 40px;
                }
            }
        }

        .btn_next,
        .btn_back {
            >img {
                width: 90px;

                @include sp {
                    display: block;
                    width: auto;
                    height: 100%;
                }
            }
        }

        #btn_result {
            >img {
                width: auto;
                height: 44px;

                @include sp {
                    display: block;
                    width: auto;
                    height: 100%;
                }
            }
        }

        #result {
            @include sp {
                padding-bottom: 0;   
            }
        }

        &>li {
            display: none;
            text-align: center;
            @include sp {
                display: none;
                text-align: center;
                padding-bottom: 10%;
            }
            &.active {
                display: block;
            }

            .check_inner {
                dl {
                    dt {
                        min-height: 170px;
                        margin-bottom: 0;
                        font-size: 25px;
                        font-family: 'Noto Serif JP', serif;
                        color: #231815;
                        letter-spacing: 0.1em;
                        @include sp {
                            min-height: 6em;
                            margin-bottom: 0;
                            font-size: 22.5px;
                            font-size: 5.921vw;
                            font-family: 'Noto Serif JP', serif;
                            color: #231815;
                            letter-spacing: 0.1em;   
                        }
                    }
                }
            }
        }

        .select-wrap {
            position: relative;
            width: 200px;
            margin: auto;

            &:before {
                z-index: 1;
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                content: "";
                line-height: 43px;
                color: #7F878C;
                pointer-events: none;
                position: absolute;
                width: 10px;
                height: 10px;
                margin: 10px 15px 0 5px;
                border-top: solid 1px #5C5B5C;
                border-right: solid 1px #5C5B5C;
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                transform: rotate(135deg);
            }

            select {
                position: relative;
                outline: none;
                -moz-appearance: none;
                text-indent: 0.01px;
                text-overflow: '';
                background: none transparent;
                vertical-align: middle;
                font-size: inherit;
                color: inherit;
                -webkit-appearance: button;
                -moz-appearance: button;
                appearance: button;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                min-height: 40px;
                padding: 8px 12px;
                border: 1px solid #5C5B5C;
                color: #5C5B5C;
                width: 100%;
                @include sp {    
                    min-height: 40px;
                    padding: 8px 12px;
                }
                &::-ms-expand {
                    display: none;
                }

                &:-moz-focusring {
                    color: transparent;
                    text-shadow: 0 0 0 #828c9a;
                }

                option {
                    background-color: #fff;
                    color: #333;
                }

            }
        }

        dd {
            >.radio {
                display: inline-block;
                text-align: left;
                @include sp {
                    display: inline-block;
                    text-align: left;
                    font-size: 3.42vw;   
                }
            }
        }

        input[type="radio"] {
            display: none;
            @include sp {
                display: none;   
            }

            &+label {
                padding-left: 30px;
                position: relative;
                @include sp {
                    padding-left: 2.5em;
                    display: inline-block;
                    position: relative;   
                }
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 2px;
                    left: -2px;
                    width: 15px;
                    height: 15px;
                    border: 1px solid #999;
                    border-radius: 50%;
                    box-sizing: content-box;
                    @include sp {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 3;
                        left: 3;
                        width: 15px;
                        height: 15px;
                        border: 1px solid #999;
                        border-radius: 50%;   
                    }
                }
            }

            &:checked {
                &+label {
                    color: #000;

                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 5px;
                        left: 1px;
                        width: 11px;
                        height: 11px;
                        background: #000;
                        border-radius: 50%;
                        @include sp {
                            content: "";
                            display: block;
                            position: absolute;
                            top: 5px;
                            left: 1px;
                            width: 11px;
                            height: 11px;
                            background: #000;
                            border-radius: 50%;   
                        }
                    }
                }
            }
        }

    }

    #btn_modal_close {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 25px;
        height: 25px;
        margin: 10px;
        cursor: pointer;
        z-index: 201;
        @include sp {
            display: block;
            position: absolute;
            top: 0;
            bottom: auto;
            right: 0;
            width: 9.85%;
            height: 0;
            padding-bottom: 9.85%;
            margin: -4.295% -4.295% 0 0;
            border-radius: 50%;
            cursor: pointer;
            z-index: 201;
            background: #231815;
        }
        &:before {
            position: absolute;
            display: block;
            content: '';
            width: 100%;
            top: 50%;
            left: 0;
            border-radius: 10px;
            border-bottom: solid 2px #231815;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            transform: rotate(-45deg);
            line-height: 1;
            @include sp {
                position: absolute;
                display: block;
                content: '';
                width: 71.21%;
                top: 50%;
                left: 50%;
                margin: -5% 0 0 -35.6%;
                border-radius: 10px;
                border-bottom: solid 2px #fff;
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                transform: rotate(-45deg);
                line-height: 1;
            }
        }

        &:after {
            position: absolute;
            display: block;
            content: '';
            width: 100%;
            top: 50%;
            left: 0;
            border-radius: 10px;
            border-bottom: solid 2px #231815;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            transform: rotate(45deg);
            @include sp {
                position: absolute;
                display: block;
                content: '';
                width: 71.21%;
                top: 50%;
                left: 50%;
                margin: -5% 0 0 -35.6%;
                border-radius: 10px;
                border-bottom: solid 2px #fff;
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                transform: rotate(45deg);
            }   
            }
        }
