/* @include _common.scss */
@import "_breakpoints";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Noto+Serif+JP:wght@300;400;500;600;700&display=swap');

html {
    width: 100%;
    min-height: 100%;
}

body {
    width: 100%;
    min-height: 100%;
    color: #000;
    background: #fff;
    line-height: 1.5;
    font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", 'Noto Sans JP', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    -webkit-text-size-adjust: 100%;
    &.no_scroll {
        @include sp {
            overflow: hidden;
        }
    }
}


.pc {
    @include tb {
        display: none;
    }
    @include sp {
        display: none;
    }
}
.pc_tablet {
    display: block;
    @include tb {
        display: block;
    }
    @include sp {
        display: none;   
    }
}
.tablet {
    display: none;
    @include tb {
        display: block;
    }
    @include sp {
        display: none;   
    }
}
.sp {
    display: none;
    @include tb {
        display: none;
    }
    @include sp {
        display: block;
    }
}

sup {
    font-size: 50%;
}

a:hover img.rollover {
    opacity: 1;
}

.mincho {
    font-family: 'Noto Serif JP', serif;
}

.yu_mincho,
.yu_mincho_bold,
.yu_mincho_midium {
    font-family: "游明朝", "YuMincho", 'Noto Serif JP', "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

.yu_mincho {
    font-weight: normal;
    &_midium {
        font-weight: 500;
    }
    &_bold {
        font-weight: 600;
    }
}

.maru_r {
    position: absolute;
    top: 0;
    right: 0;
    font-family: 'Noto Serif JP';
    font-size: 24px;
    @include sp {
        font-size: 3vw;   
    }
}

img {
    @include sp {
        width: 100%;  
    }
}

a {
    &:hover {
        opacity: .7;
        transition: .2s;
    }
    
}
.keep {
    position: relative;
    display: inline-block;
    padding-right: 0.5em;
}

/*div.inner {
    width: 1000px;
    margin: auto;
    @include tb {
        max-width: 1000px;
        width: auto;
        margin: auto;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;   
    }
}*/

#container {
    padding-top: 70px;
    overflow: hidden;
    @include tb {
        padding-top: 70px;
    }
    @include sp {
        padding-top: 65px;
    }
}

.section {
    >.inner {
        width: 1000px;
        margin: auto;
        @include tb {
            max-width: 1000px;
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
            box-sizing: border-box;
        }
        @include sp {
            width: 100%;   
        }
    }
}

header {
    position: fixed;
    top: 0;
    width: 100%;
    min-height: 70px;
    background: #f5f5f5;
    padding: 15px 0 0;
    box-sizing: border-box;
    z-index: 10;
    @include tb {
        padding: 13px 20px 13px 20px;
        width: 100%;
        height: 70px;
        box-sizing: border-box;
        z-index: 100;   
    }
    @include sp {
        height: 65px;
        min-height: 65px;
        max-height: 65px;
        padding: 13px 5.265%;
        z-index: 100;   
    }

    h1,
    #logo {
        line-height: 1;

        a {
            height: auto;
            display: block;
            margin-right: 0%;
        }
        img {
            @include sp {
                width: 113px;
            }
        }
    }

    h1 {
        img {
            width: 132px;
            @include tb {
                width: 66.16%;   
            }
            @include sp {
                width: 113px;
            }
        }
    }

    & > div.inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 1000px;
        margin: auto;
        @include tb {
            max-width: 1000px;
            width: auto;
            margin: auto;
            padding-left: 20px;
            padding-right: 20px;
            box-sizing: border-box;
            padding: 0;
        }
    }

    &.no_menu {

        h1,
        #logo {
            line-height: 1;
            text-align: center;
        }
    }

    #menu {
        display: none;
        @include tb {
            display: block;
            position: absolute;
            top: 50%;
            right: 20px;
            width: 60px;
            height: 0;
            padding-bottom: 40px;
            margin-top: -20px;
            cursor: pointer;
            text-align: center;
            z-index: 103;
        }
        @include sp {
            right: 5.265%;
            width: 8.08%;
            height: 0;
            padding-bottom: 5.26%;
            margin-top: -2.63%;
        }
    
        span {
            @include tb {
                display: block;
                position: absolute;
                width: 100%;
                border-bottom: solid 2px #000;
                transition: .25s ease-in-out;
                left: 0;
                border-radius: 10px;        
            }
        }
    
        span:nth-child(1) {
            @include tb {
                top: 0;    
            }
        }
    
        span:nth-child(2) {
            @include tb {
                top: 50%;
                margin-top: -1px;         
            }
        }
    
        span:nth-child(3) {
            @include tb {
                bottom: 0;         
            }
        }
    
        &.active {
            span {
                @include tb {
                    border-bottom: solid 2px #fff;    
                }
            }
    
            span:nth-child(1) {
                @include tb {
                    top: 50%;
                    left: 0;
                    transform: rotate(-45deg);              
                }
            }
    
            span:nth-child(2) {
                @include tb {
                    opacity: 0;      
                }
            }
    
            span:nth-child(3) {
                @include tb {
                    top: 50%;
                    bottom: auto;
                    transform: rotate(45deg);         
                }
            }
        }
    }

    .l-nav {
            width: 775px;
            margin: 16px 0 0 auto;        
    }

    .c-nav {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        @include tb {
            display: none;
        }
        &Item {
            position: relative;
            margin-right: 25px;
            &.--skincare {
                width: 78px;
                @include tb {
                    width: 100%;
                }
            }
            &.--supplement {
                width: 95px;
                @include tb {
                    width: 100%;
                }
            }
            &.--media {
                width: 54px;
                margin-right: 15px;
                @include tb {
                    width: 100%;
                    margin-right: 0;
                }
            }
            &.--voice {
                width: 70px;
                margin-right: 15px;
                @include tb {
                    width: 100%;
                    margin-right: 0;
                }
            }
            &.--course {
                width: 63px;
                @include tb {
                    width: 100%;
                }
            }
            &.--shopping_guide {
                width: 131px;
                margin-right: 0;
                @include tb {
                    width: 100%;
                }
            }
            &.--faq {
                width: 85px;
                margin-right: 30px;
                @include tb {
                    width: 100%;
                    margin-right: 0;
                }
            }

            a {
                &:hover {
                    opacity: 1;
                    .js-navHover {
                        opacity: 0;
                        @include tb {
                            opacity: 0.7;
                            transition: .5s;
                        }
                    }
                    .c-navItemText {
                        opacity: 1;
                    }
                }
            }

            &Img {
                position: relative;
                background: #f5f5f5;
                z-index: 2;
                @include tb {
                    background: none;
                    width: 280px;
                }
                @include sp {
                    width: calcSp(412);
                }
            }
            &Text {
                position: absolute;
                top: 0;
                z-index: 1;
                width: 100%;
                font-size: 12.5px;
                text-align: center;
                letter-spacing: 0;
                font-weight: 700;
                opacity: 0;
                white-space: nowrap;
                font-feature-settings: 'palt' 1;
                @include tb {
                    display: none;
                }
                span {                                   
                    display: inline-block;
                    border-bottom: solid 1px #000;
                    text-align: center;
                }
            }
            
        }
    }

    &.active {
        nav {
            @include tb {
                display: block;
                background: #b1acaa;
                opacity: .95;
                position: fixed;
                width: 100%;
                height: 100vh;
                margin: 0;
                top: 0;
                left: 0;
                padding: 120px 0 0;
                z-index: 102;
                overflow-y: scroll;
                box-sizing: border-box;
            }
            @include sp {
                padding: calcSp(120) 0 0;
            }
            
            &>ul {
                @include tb {
                    display: block;
                    margin-bottom: 30%;                 
                }
                @include sp {
                    margin-bottom: calcSp(30);
                }
                &>li {
                    @include tb {
                        width: 100%;
                        text-align: center;
                        margin-bottom: 30px;
                        padding-bottom: 0;
                        margin-right: auto;                 
                    }
                    &>a {
                        @include tb {
                            height: auto;
                            display: inline-block;                        
                        }
                    }
                }
            }
        }
    }
    
}

footer {
    background: #b1acaa;
    padding: 40px 0;
    @include sp {
        padding: 10.13% 0 2.63%;
    }

    div.inner {
        position: relative;
        width: 1000px;
        margin: auto;
        @include tb {
            max-width: 1000px;
            width: auto;
            margin: auto;
            padding-left: 20px;
            padding-right: 20px;
            box-sizing: border-box;   
        }
        @include sp {
            position: relative;
			padding-right: 0;
			padding-left: 0;
			text-align: center;
        }
    }

    #flogo {
        text-align: center;
        margin-bottom: 80px;
        @include sp {
            margin: 0 auto 10.52%;   
        }
        img {
            width: 54px;
            @include sp {
                width: 10.13%;   
            }
        }
    }

    #footerNav {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 50px;
        @include sp {
            display: block;
        }
        >div {
            
            margin-right: 0;
            @include sp {
                width: 54.11%;
                float: none;
                margin: auto;   
            }
            &:last-child {
                margin-right: 0;
                @include sp {
                    padding-bottom: 15%;   
                }
            }
            
            >p {
                margin-bottom: 18px;
                padding-bottom: 9px;
                border-bottom: 1px solid #fff;
                @include sp {
                    width: calcSp(458);
                    margin: 0 auto calcSp(23);
                    padding-bottom: calcSp(15);
                    line-height: 1;
                    text-align: center;
                }
                img {
                    @include sp {
                        width: auto;
                        height: 14px;   
                    }
                }
            }

            > ul {
                @include sp {
                    width: 50.4%;
                    margin: auto;
                    text-align: left;   
                }
                > li {
                    margin-bottom: 7px;
                    @include sp {
                        margin-bottom: 10px;
                        line-height: 1;
                        text-align: center;
                    }
                    &:last-child {
                        @include sp {
                            margin-bottom: 0;   
                        }
                    }
                    img {
                        @include sp {
                            width: auto;
                            height: 10px;   
                        }
                    }
                }

                ul {
                    margin-top: 10px;
                    padding-left: 40px;
                    @include sp {
                        position: relative;
                        margin-top: 0;
                        padding-top: 10px;
                        padding-bottom: 0;
                        padding-left: 0;   
                    }
                    > li {
                        position: relative;
                        margin-bottom: 0;
                        @include sp {
                            position: relative;
                            margin-bottom: 5px;
                            line-height: 1;
                            box-sizing: border-box;
                            padding-left: 10%;   
                        }
                        &:before {
                            position: absolute;
                            top: 50%;
                            right: 100%;
                            display: block;
                            content: '';
                            width: 14px;
                            height: 1px;
                            margin: 0 15px 0 0;
                            background-color: #fff;
                            @include sp {
                                position: absolute;
                                top: 50%;
                                left: 0;
                                display: block;
                                content: '';
                                width: 14px;
                                height: 1px;
                                margin: 0;
                                background-color: #fff;   
                            }
                        }
                        &:last-child {
                            @include sp {
                                margin-bottom: 0;   
                            }
                        }
                    }
                }

            
            }

            &#fnav_brand {
                width: 190px;
                @include sp {
                    width: auto;
                    margin-bottom: 10%;   
                }
                p {
                    >img {
                        @include sp {
                            width: auto;
                            height: 14px;   
                        }
                    }
                }  
            }

            &#fnav_shop {
                width: 201px;
                @include sp {
                    width: auto;
                    margin-bottom: 10%;   
                }
                li {
                    &:first-child img {
                        height: 15px;
                        @include sp {
                            width: auto;
                            height: 12px;
                            max-width: 100%;   
                        }
                    }
                }
                p {
                    >img {
                        @include sp {
                            width: auto;
                            height: 14px;   
                        }
                    }
                }  
            }

            &#fnav_company {
                width: 304px;
                @include sp {
                    width: auto;
                    margin-bottom: 10%;   
                }
                p {
                    >img {
                        @include sp {
                            width: auto;
                            height: 14px;   
                        }
                    }
                }    
            }



            #sns {
                position: absolute;
                top: 0;
                right: 0;

                li {
                    float: left;
                    margin-right: 25px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                img {
                    width: auto;
                    height: 28px;
                }
            }

            img {
                width: auto;
                height: 12px;
                @include sp {
                    height: 10px;
                    max-width: 100%;   
                }
            }

            p img {
                width: auto;
                height: 16px;

            }
        }
    }

    #copy {
        margin-top: 30px;
        @include sp {
            position: absolute;
            bottom: 0;
            left: 0;
            margin-left: 4%;   
        }
        img {
            width: auto;
            height: 15px;
            @include sp {
                width: auto;
                height: 3.026vw;   
            }
        }
    }

    #btn_pagetop {
        position: absolute;
        bottom: 0;
        right: 0;
        @include sp {
            width: 6.97%;
            margin-right: 4%;
        }
        img {
            width: 34px;
            @include sp {
                width: 100%;
            }
        }
    }
}

.form-group {
    img {
        width: auto;
    }
}

#modal_movie {
    visibility: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .5);
    z-index: 300;
    opacity: 0;
    transition: .5s;
    transition-duration: .5s;

    &.open {
        visibility: visible;
        opacity: 1;
        transition: .5s;
        transition-duration: .5s;
    }

    >.inner {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 437px;
        display: block;
        margin-top: -183px;
        margin-left: -380px;
        width: 760px;
    }

    ul {
        margin: auto;
        width: 100%;
        display: block;
        text-align: center;
    }

    iframe {
        width: 760px;
        height: 427px;
    }

    .modal_inner {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 700px;
        height: 465px;
        margin: -233px 0 0 -350px;

        #modal_movie_close {
            position: absolute;
            right: 0;
            display: block;
            width: 50px;
            height: 0;
            margin-top: -65px;
            padding-bottom: 50px;
            background-image: url("../img/index/modal_close.png");
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
        }
    }
}

#products,
#products2 {
    padding-top: 40px;

    .inner {
        background: url(../img/index/img_defential_award.jpg) 50% 45px / auto no-repeat;
        @include sp {
            background: url(../img/index/img_defential_award_sp.jpg) 50% 10% / contain no-repeat;/*award*/
        }
    }

    .cont {
        position: relative;
        padding-top: 516px;
        width: 635px;
        margin: 0 auto 130px;
        font-size: 18px;
        @include sp {
            position: relative;
            padding-top: 100%;
            width: 85.52%;
            margin: 5.07% auto 34.32%;
            font-size: initial;
        }

        h4 {
            display: inline-block;
            @include sp {
                display: block;
                margin-bottom: 5.52%;
            }
            img {
                @include sp {
                    width: 100%;
                }
            }
        }

        .btn_all_item {
            margin-left: 22px;
            @include sp {
                display: block;
                width: 37.07%;
                margin-left: 0;
            }
            img {
                @include sp {
                    width: 100%;   
                }
            }
        }

        p {
            margin-top: 25px;
            margin-bottom: 15px;
        }

        ul {
            font-size: 18px;
        }

        .price,
        .sale {
            display: inline-block;
            text-align: left;
        }

        .comment {
            margin-bottom: 30px;
            font-size: 14px;
        }

        .sale img {
            vertical-align: text-bottom;
            padding: 0 12px;
        }

        .try {
            width: 602px;
            margin: 0 auto 230px;

            img {
                position: absolute;
                padding-top: 25px;
                width: 394px;
                height: auto;
            }

            a {
                padding-left: 430px;
            }
        }
    }
}

#new_member {
    padding: 40px 0;

    h3 {
        margin-bottom: 60px;
        text-align: center;
    }
}

#course,
#course2 {
    margin-bottom: 155px;
    padding: 75px 0 75px;
    background: url(../img/index/bg_course.jpg) center /cover repeat-x;
    box-sizing: content-box;
    @include sp {
        margin-bottom: 15.13%;
        padding: 9.21% 0 9.86%;
        background: url(../img/index/bg_course_sp.jpg) top center repeat-y;
    }

    h3 {
        padding-bottom: 40px;
        @include sp {
            padding-bottom: 7.23%;   
        }
    }
    h4 {
        display: block;
        width: auto;
        margin: 0 auto 70px;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        line-height: 1;
        letter-spacing: 0.2em;
        vertical-align: text-top;
        @include sp {
            display: block;
            width: auto;
            margin: 0 auto 9.57%;
            font-size: 17px;
            font-size: 4.474vw;
            font-weight: 600;
            text-align: center;
            line-height: 1;
            letter-spacing: 0.2em;   
        }

        >span {
            display: inline-block;
            padding: 0;
            border-left: none;
            border-right: none;
            @include sp {
                display: inline-block;
                width: 83.63%;
                padding: 0;
                border-left: none;
                border-right: none;
                letter-spacing: .1em;
                line-height: 1; 
            }
            >img {
                width: 456px;
                @include sp {
                    vertical-align: top;
                    width: 100%;
                }
            }
        }
    }

    .box {
        background: #fff;
        padding: 75px 85px 40px;
        @include sp {
            background: #fff;
            padding: 10% 7.23% 0 7.23%;
            width: 73.7%;
            margin: auto;   
        }
    }
    .list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        dl {
            width: 305px;
            margin-bottom: 45px;
            padding-left: 85px;
            background-position: 7px 48px;
            background-repeat: no-repeat;
            background-size: auto;

            @include sp {
                width: auto;
                margin-bottom: 9.2%;
                padding-left: 17.85%;
                background-position: top left;
                background-repeat: no-repeat;
                background-size: 12.72%;
            }

            &:nth-child(odd) {
                margin-right: 45px;
                @include sp {
                    margin-right: 0;   
                }
            }
            &:first-child {
                background-image: url("../img/index/icon_10off.png");
            }
            &:nth-child(2) {
                background-image: url("../img/index/icon_free.png");
            }
    
            &:nth-child(3) {
                background-image: url("../img/index/icon_cycle.png");
            }
    
            &:nth-child(4) {
                background-image: url("../img/index/icon_calendar.png");
                & dt {
                    img {
                        width: 288px;
                    }

                }
            }
    
            &.icon10per {
                background-image: url("../img/index/icon_10off.png");
            }
    

            

            dt {
                margin-bottom: 10px;
                margin-left: 0;
                text-indent: 0;
                font-weight: 600;
                font-size: 23px;
                letter-spacing: 1px;
                line-height: 1.5;
                width: 288px;
                @include sp {
                    margin-bottom: 3.57%;
                    width: 100%;
                    font-weight: 600;
                    font-size: 16px;
                    font-size: 4.2vw;
                    letter-spacing: 1px;
                    line-height: 1.5;
                }
                img {
                    width: 222px;
                    @include sp {
                        width: 100%;
                    }
                }
            }

            dd {
                font-size: 16px;
                text-justify: inter-word;
                text-justify: inter-ideograph;
                text-justify: inter-character;
                text-align: justify;
                line-height: 2;
                @include sp {
                    font-size: 16px;
                    font-size: 3vw;
                    text-justify: inter-word;
                    text-justify: inter-ideograph;
                    text-justify: inter-character;
                    text-align: justify;
                    line-height: 2;
                }
                span {
                    display: block;
                    margin-top: 5px;
                    text-indent: -1em;
                    text-align: left;
                    padding-left: 1em;
                    font-size: 14px;
                    @include sp {
                        display: block;
                        margin-top: 2%;
                        text-indent: -1.2em;
                        padding-left: 1.2em;
                        letter-spacing: 0.1em;
                        font-size: 10px;
                        font-size: 2.6vw;
                        line-height: 1.5;
                        text-align: left;
                        text-justify: none;
                    }
                    
                }
            }
        }
    }
}

#products2>.inner {
    background: url(../img/index/img_defential_award_sp.jpg) 50% 15% / contain no-repeat;/*award*/
  }/*award*/

#ukokkei-landing-page-container {
    img {
        width: auto !important;
    }
}